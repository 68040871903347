import "./Home.css";
import logo from "../../resources/imgs/logo.png";
import homeImg from "../../resources/imgs/home.png";
import acountImg from "../../resources/imgs/account.png";
import mineImg from "../../resources/imgs/mine.png";
import { useNavigate } from "react-router-dom";

export default function Home() {
    let navigate = useNavigate();

    return (
        <div className="home">
            <div className="home_link">
                <span onClick={navPrivacy}>隐私政策</span>
            </div>
            <div className="home_header">
                <img className="home_logo" src={logo} />
                <div className="home_title">
                    <span>简龟记账</span>
                    <span>让生活更简单</span>
                </div>
            </div>
            <div className="home_content">
                <div className="home_img">
                    <img className="home_img_item" src={homeImg} />
                    <img className="home_img_item" src={acountImg} />
                    <img className="home_img_item" src={mineImg} />
                </div>
            </div>
            <div className="home_download">
                <button>IOS版下载</button>
                <button>安卓版下载</button>
            </div>
            <div className="home_footer">
                <span>长沙市天心区蒋创网络信息技术工作室.</span>&nbsp;&nbsp;
                <span>All Rights Reserved.</span>&nbsp;&nbsp;
                <span>联系我们：446084066@qq.com</span>&nbsp;
                <span>
                    <a href="http://beian.miit.gov.cn/">|&nbsp;湘ICP备2022019684号</a>
                </span>
            </div>
        </div>
    );

    function navPrivacy() {
        navigate("/privacy");
    }
}
