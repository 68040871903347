import "./App.css";
import Home from "./pages/Home/Home";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import Privacy from "./pages/Home/Privacy/Privacy";

function App() {
    return (
        <div className="App">
            {/* <Home /> */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </div>
    );
}

export default App;
