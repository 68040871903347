import "./Privacy.css";

export default function () {
    return (
        <div className="privacy">
            <span class="privacy_privacy_title">隐私政策</span>

            <span>您在使用蒋创网络信息技术工作室的产品简龟记账(以下简称“我们”)的服务时，我们可能会收集和使用您的相关信息。</span>
            <span>我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。</span>
            <span>本《隐私政策》与您所使用的服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。</span>
            <span>您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。 如对本《隐私政策》或相关事宜有任何问题，请通过446084066@qq.com与我们联系。</span>

            <span class="privacy_privacy_title">我们可能收集的信息</span>

            <span>我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</span>

            <span class="privacy_title">您提供的信息</span>

            <span>您注册并使用简龟记账时，需要注册账户，我们提供了以下几种注册方式</span>
            <span>微信注册，需要经第三方应用授权，并获取唯一标识，头像，昵称等信息</span>

            <span class="privacy_title">我们获取的您的信息</span>

            <span>您使用服务时我们可能收集如下信息：</span>
            <span>日志信息，指您使用我们的服务时，系统自动采集的相关信息，包括：闪退信息，页面存留信息，应用版本信息等；</span>
            <span>应用数据，在使用应用服务时，将应用数据存储在我们的服务器中；</span>

            <span class="privacy_title">第三方SDK获取的信息</span>
            <span>使用SDK名称：第三方登录SDK； 服务类型：用户第三方平台授权登录； 收集个人信息类型：微信平台的头像，昵称；</span>

            <span class="privacy_title">我们可能如何使用信息</span>

            <span>我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</span>
            <span>向您提供服务；</span>
            <span>在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</span>
            <span>帮助我们设计新服务，改善我们现有服务；</span>
            <span>使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；</span>
            <span>软件认证或管理软件升级；</span>
            <span>让您参与有关我们产品和服务的调查。</span>
            <span>
                为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
            </span>
            <span>
                我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
            </span>
            <span class="privacy_title">我们可能分享的信息</span>
            <span>除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：</span>
            <span>实现“我们可能如何使用信息”部分所述目的；</span>
            <span>履行我们在本《隐私政策》中的义务和行使我们的权利；</span>
            <span>理解、维护和改善我们的服务。</span>
            <span>随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。</span>
            <span>我们还可能为以下需要而保留、保存或披露您的个人信息：</span>
            <span>遵守适用的法律法规；</span>
            <span>遵守法院命令或其他法律程序的规定；</span>
            <span>遵守相关政府机关的要求；</span>
            <span>为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们、其他用户的人身和财产安全或合法权益所合理必需的用途</span>

            <span class="privacy_title">我们如何保存你的信息</span>

            <span>保存期限</span>
            <span>
                您在使用本平台期间，我们将持续保存您的个人信息，保存期限将以不超过为您提供服务所必须的期间为原则。在您终止使用本平台或关闭相应授权后，除法律法规对于特定信息保留期限另有规定外，我们会对您的信息进行删除或做匿名化处理。
            </span>
            <span>如我们因经营不善或其他原因出现停止运营的情况，我们会立即停止对您个人信息的收集，并删除已收集的个人信息。我们会将此情况在网站上进行公告或以站内通知、邮件等其他合理方式逐一传达到各个用户。</span>
            <span>保存地域</span>
            <span>我们将从中华人民共和国境内获得的信息存放于中华人民共和国境内。</span>
            <span>注销您的账户</span>
            <span>您可以通过进入底部Tab菜单最后一个「我的」，点击「登出」自行登出账户。</span>

            <span class="privacy_title">信息安全</span>

            <span>我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。</span>
            <span>
                我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            </span>

            <span class="privacy_title">与服务有关的公告</span>

            <span>我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</span>

            <span class="privacy_title">隐私政策的适用例外</span>

            <span>我们的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站）。例如：</span>
            <span>您利用 “分享”键将某些内容分享到我们的服务，或您利用第三方连线服务登录我们的服务。这些功能可能会收集您的相关信息（包括您的日志信息），并可能在您的电脑装置cookies，从而正常运行上述功能；</span>
            <span>我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站。</span>
            <span>
                该等第三方社交媒体或其他服务可能由相关的第三方或我们运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非《通用服务条款》或本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。
            </span>

            <span class="privacy_title">未成年人使用我们的服务</span>

            <span>我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。</span>

            <span class="privacy_title">隐私政策的适用范围</span>

            <span>
                除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。
            </span>
            <span>请您注意，本《隐私政策》不适用于以下情况：</span>
            <span>通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</span>
            <span>通过在我们服务中进行广告服务的其他公司或机构所收集的信息。</span>

            <span class="privacy_title">变更</span>

            <span>
                我们可能适时修订本《隐私政策》的条款，修订构成本《隐私政策》的一部分。如修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
            </span>

            <span class="privacy_title">关于我们</span>

            <span>企业名称：蒋创网络信息技术工作室</span>
            <span>注册地址：湖南省长沙市天心区金盆岭街道黄土岭社区芙蓉中路三段380号汇金苑9栋3301~3303、3310~3315室</span>
            <span>联系方式：446084066@qq.com</span>
        </div>
    );
}
